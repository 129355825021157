@tailwind base;
@tailwind components;
@tailwind utilities;

/* .rhap_progress-indicator{
    display: none;
} */
/* .rhap_progress-filled{
    background-color: red;
} */
/* .rhap_download-progress{
    background-color: gray;
} */
/* .rhap_progress-container{
    margin: 0;
    height: 0;
} */
.rhap_container {
  padding: 0;
  /* height: 90px; */
}
/* .rhap_progress-section{
    margin: 0;
} */
.rhap_controls-section {
  margin-top: 0px !important;
  width: auto;
}

/* volume slider */
.rc-slider-disabled {
  background-color: transparent !important;
}

@layer components {
  .music-playlist-card-bg {
    @apply relative p-3 mx-3 rounded-lg;
  }

  .music-playlist-card-bg:hover {
    @apply bg-[#2e2e2e];
  }

  .music-playlist-card-remove-btn {
    @apply absolute font-extrabold text-red-600 duration-300 ease-in-out cursor-pointer right-3;
  }
  .music-playlist-card-remove-btn:hover {
    @apply scale-[1.5];
  }

  .music-playlist-card-choose-song-btn {
    @apply flex items-center justify-start gap-2 pr-8 ml-2 cursor-pointer max-sm:gap-5 max-sm:my-2;
  }

  .music-playlist-card-artist-name {
    @apply text-[#7B9DB4] text-[12px] font-thin;
  }

  /* audio player css */
  .audio-player-back-btn-bg {
    @apply absolute top-0 z-40 left-10;
  }
  .audio-player-close-btn-bg {
    @apply absolute top-0 z-40 right-10;
  }
  .audio-player-back-btn-arrow {
    @apply !z-50 flex items-center justify-center w-10 h-10 text-2xl bg-white text-black rounded-full cursor-pointer;
  }
  .audio-player-playing-now-div {
    @apply relative flex items-center justify-center w-full;
  }
  /* .audio-player-playing-now-p{
        @apply absolute z-40 mx-auto text-white sm:top-[10px] max-sm: top-0
    } */

  /* audio player ! full screen */
  .audio-player-container {
    @apply bg-[#1F1F1F] w-full;
  }
  .audio-player-minimized {
    @apply flex !items-center h-[80px] justify-between gap-2 text-white px-5 max-sm:px-4 duration-500;
  }
  .audio-player-full-screen {
    @apply min-h-screen overflow-y-scroll z-40 duration-300;
  }
}
